import {Search} from '@mui/icons-material'
import {Box} from '@mui/material'
import React, {FC, useEffect, useState} from 'react';
import {EventCallback} from '~@core/type/Common'
import '~@app/module/common/component/SearchBox.scss'

export type SearchBoxProps = {
  onSearch?: EventCallback
  placeholder?: string
  value?: string
}

export const SearchBox: FC<SearchBoxProps> = ({onSearch, placeholder, value}) => {
  const [text, setText] = useState('')

  useEffect(() => {
    setText(value || text)
  }, [value]);

  const submit = () => {
    onSearch && onSearch(null, text)
  }

  return <div className="search-box">
    <input className="input-search"
      value={text}
      placeholder={placeholder}
      onChange={(event) => {
        setText(event.target.value)
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          submit()
        }
      }}
    />
    <Box className="icon" onClick={submit} sx={{color: 'rgba(0, 0, 0, 0.87)'}}>
      <Search style={{fontSize: '40px'}}/>
    </Box>
  </div>
}