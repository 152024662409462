import {Box, BoxProps} from "@mui/material"
import React, {FC, useMemo} from 'react';
import Image, {ImageProps} from "~@core/component/common/Image"
import '~@app/module/common/component/RichImage.scss'

interface RichImageProps extends BoxProps {
  w?: number,
  h?: number,
  src?: string
  ImageProps?: ImageProps
}

const RichImage: FC<RichImageProps> = ({className = '', w = 1, h = 1, src, ImageProps, ...props}) => {
  const ratio = useMemo(() => {
    const w1 = w || 1;
    const h1 = h || 1;
    return `${(h1 * 100 / w1).toFixed(2)}% !important`
  }, [w, h])

  return <Box className={`rich-image ${className}`} sx={{paddingBottom: ratio}} {...props}>
    <Image {...ImageProps} url={src ?? ImageProps?.url}/>
  </Box>
};

export default RichImage;