import React from 'react';
import FamilyMember from "~@app/module/core/model/FamilyMember"
import MemberImage from "~@app/module/family/component/member/MemberImage"
import {Card, CardContent} from "@mui/material"
import FamilyUtil from "~@app/module/family/util/FamilyUtil"
import useApp from "~@app/component/app/UseApp"
import "~@app/module/family/component/member/MemberCard.scss"

export interface MemberCardProps extends React.HTMLProps<HTMLDivElement> {
  member: FamilyMember
}

const MemberCard = ({member, ...props}) => {
  const app = useApp()
  return <div className="member-card" {...props}>
    <Card className="card">
      <MemberImage
        member={member}
        ImageProps={{
          width: "100%"
        }}
      />
      <CardContent className="card-content">
        <h4 className="title">
          <a href={app.pageUrl('/family/member', {id: member.id})} className="link">{member.name}</a>
        </h4>
        <p className="date">({FamilyUtil.yobyod(member.dobYear, member.dodYear)})</p>
      </CardContent>
    </Card>
  </div>
};

export default MemberCard;