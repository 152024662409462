import {BoxProps} from "@mui/material"
import React, {FC} from 'react';
import useApp from "~@app/component/app/UseApp"
import RichImage from "~@app/module/common/component/RichImage"
import FamilyMember from "~@app/module/core/model/FamilyMember"
import {ImageProps} from "~@core/component/common/Image"

interface MemberImageProps extends BoxProps {
  member?: FamilyMember,
  ImageProps?: ImageProps,
  className?: string
}

const MemberImage: FC<MemberImageProps> = ({member, className, ImageProps, ...props}) => {
  const app = useApp();
  if (!member) {
    return null;
  }

  return <RichImage className={`member-image ${className || ''}`}
                    ImageProps={{
                      ...ImageProps,
                      url: member?.mainImage?.links?.medium || app.mediaUrl('static/media/image/app/default-portrait.jpg'),
                    }}
                    {...props}
  />
};

export default MemberImage;