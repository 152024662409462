import React from "react";
import DefaultPageBoot from "~@app/boot/DefaultPageBoot"
import HomePage from "~@app/module/home/page/HomePage";

export default function Home() {
  return (
    <DefaultPageBoot authenticated>
      <HomePage/>
    </DefaultPageBoot>
  )
}