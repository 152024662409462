import {Grid} from "@mui/material"
import _ from "lodash"
import React, {useMemo} from 'react';
import '~@app/module/home/component/MemberCarousel.scss'
import FamilyMember from "~@app/module/core/model/FamilyMember"
import MemberCard from "~@app/module/family/component/member/MemberCard"
import FamilyUtil from "~@app/module/family/util/FamilyUtil"
import {trans} from "~@core/util/StringUtil"

export interface MemberCarouselProps {
  members?: FamilyMember[]
}

const MemberCarousel = ({members}) => {
  const monthMap = useMemo(() => {
    return {
      1: trans('jan'),
      2: trans('feb'),
      3: trans('mar'),
      4: trans('apr'),
      5: trans('may'),
      6: trans('jun'),
      7: trans('jul'),
      8: trans('aug'),
      9: trans('sep'),
      10: trans('oct'),
      11: trans('nov'),
      12: trans('dec'),
    }
  }, [])

  function renderDate(member: FamilyMember) {
    if (FamilyUtil.isDead(member)) {
      return <>
        <div className="top">Ngày mất</div>
        <div className="bottom">{FamilyUtil.partialDateTime(member.dodDay, member.dodMonth, undefined)}</div>
      </>
    }
    return <>
      <div className="top">Sinh Nhật</div>
      <div className="bottom">{FamilyUtil.partialDateTime(member.dobDay, member.dobMonth, undefined)}</div>
    </>
  }

  const renderCarouselItem = (member: FamilyMember) => {
    return <Grid item key={member.id} xs={15} md={3} className="member-item">
      <div className="member-item-wrapper">
        <div className={`anni-date ${FamilyUtil.isDead(member) ? 'dead' : 'alive'}`}>
          {renderDate(member)}
        </div>
        <MemberCard member={member}/>
      </div>
    </Grid>
  }

  return <div className="member-carousel">
    <Grid container columns={15} spacing={4}>
      {_.map(members, renderCarouselItem)}
    </Grid>
  </div>
};

export default MemberCarousel;