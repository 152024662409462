import {ArrowRightAlt} from '@mui/icons-material'
import {Button, Container} from '@mui/material'
import _ from 'lodash'
import React, {useState} from 'react';
import FamilyMember from '~@app/module/core/model/FamilyMember'
import FamilyService from '~@app/module/core/service/FamilyService'
import useFamily from '~@app/module/family/context/FamilyContext'
import FamilyUtil from '~@app/module/family/util/FamilyUtil'
import MemberCarousel from '~@app/module/home/component/MemberCarousel'
import '~@app/module/home/component/section/TimelineSection.scss'
import {useAsyncEffect} from '~@core/hook/ReactHooks'
import {trans} from '~@core/util/StringUtil'

const TimelineSection = () => {
  const {family} = useFamily();
  const [members, setMembers] = useState<FamilyMember[]>()
  const [numberOfItems, setNumberOfItems] = useState(5)

  useAsyncEffect(async () => {
    if (family) {
      const [data] = await FamilyService.get(family.id, {
        with: [['sortedMembers', {
          with: ['*', 'mainImage']
        }]]
      });


      const curMonth = new Date().getMonth() + 1;
      const thisMonth = _.chain(data.sortedMembers)
        .map(e => {
          const isDead = FamilyUtil.isDead(e)
          return {
            ...e,
            _day: isDead ? e.dodDay : e.dobDay,
            _month: isDead ? e.dodMonth : e.dobMonth,
            _year: isDead ? e.dodYear : e.dobYear,
          }
        })
        .filter((e) => !!(e._month && (e._month === curMonth || e._month === curMonth + 1)))
        .sortBy('_month', '_day')
        .value();
      const next = _.chain(data.sortedMembers)
        .map(e => {
          const isDead = FamilyUtil.isDead(e)
          return {
            ...e,
            _day: isDead ? e.dodDay : e.dobDay,
            _month: isDead ? e.dodMonth : e.dobMonth,
            _year: isDead ? e.dodYear : e.dobYear,
          }
        })
        .filter((e) => !!(e._month && e._month > curMonth + 1))
        .sortBy('_month', '_day')
        .value();
      const nextYear = _.chain(data.sortedMembers)
        .map(e => {
          const isDead = FamilyUtil.isDead(e)
          return {
            ...e,
            _day: isDead ? e.dodDay : e.dobDay,
            _month: isDead ? e.dodMonth : e.dobMonth,
            _year: isDead ? e.dodYear : e.dobYear,
          }
        })
        .filter((e) => !!(e._month && e._month < curMonth))
        .sortBy('_month', '_day')
        .value();

      let members = thisMonth;
      if (_.size(members) < numberOfItems) {
        members = _.slice([...thisMonth, ...next, ...nextYear], 0, numberOfItems)
      }
      setMembers(members);
    }
  }, [family, numberOfItems]);

  return <div className="timeline-section section">
    <Container>
      <h2 className="second-font heading2">{trans('family:upcomingAnniversaries')}</h2>
      <Button endIcon={<ArrowRightAlt/>}
        color="error"
        size="large"
        onClick={() => {
          setNumberOfItems(20);
        }}>{trans('family:openTimeline')}</Button>
      <div className="mt-3">
        <MemberCarousel members={members}/>
      </div>
    </Container>
  </div>
};

export default TimelineSection;