import React, {PropsWithChildren, useEffect} from 'react';
import {Grid, Paper} from '@mui/material';
import usePage from '~@app/component/page/UsePage';
import {BreadcrumbItem} from '~@core/component/Type';

export interface SimplePageProps {
  title?: string;
  breadcrumbs?: BreadcrumbItem[];
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  variant: 'default' | 'blank';
}

export default function SimplePage({
  title, breadcrumbs, leftElement, rightElement, variant = 'default', ...props
}: PropsWithChildren<SimplePageProps>): React.ReactElement {
  const {setBreadcrumbs, setPageTitle} = usePage();

  useEffect(() => {
    setBreadcrumbs(breadcrumbs || []);
  }, [JSON.stringify(breadcrumbs)]);

  useEffect(() => {
    if (title !== null && title !== undefined) {
      setPageTitle(title);
    }
  }, [title]);

  function renderContent() {
    if (variant === 'default') {
      return (
        <Paper className="p-4 w-100">
          {props.children}
        </Paper>
      );
    }
    if (variant === 'blank') {
      return <div className="w-100">{props.children}</div>;
    }
    return null;
  }

  return (
    <div className="simple-page">
      <div className="simple-page-main">
        <Grid container>
          {leftElement ? <Grid item xs={3} className="simple-page-left">{leftElement}</Grid> : null}
          <Grid item xs className="simple-page-center">
            {renderContent()}
          </Grid>
          {rightElement ? <Grid item xs={3} className="simple-page-right">{rightElement}</Grid> : null}
        </Grid>
      </div>
    </div>
  );
}