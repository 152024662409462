import {ArrowRightAlt} from "@mui/icons-material"
import {Button, Container} from "@mui/material"
import React, {useState} from 'react';
import useApp from "~@app/component/app/UseApp"
import BlogGrid from "~@app/module/blog/component/BlogGrid"
import Blog from "~@app/module/core/model/Blog"
import BlogService from "~@app/module/core/service/BlogService"
import useFamily from "~@app/module/family/context/FamilyContext"
import {useAsyncEffect} from "~@core/hook/ReactHooks"
import {trans} from "~@core/util/StringUtil"
import '~@app/module/home/component/section/BlogSection.scss'

const BlogSection = () => {
  const app = useApp()
  const [blogs, setBlogs] = useState<Blog[]>()
  const {family} = useFamily()

  useAsyncEffect(async () => {
    const [data] = await BlogService.list({
      with: ['*', 'gallery.mediaData', 'mainImage', ['creator', {with: ['*']}]],
      filters: [['familyId', '=', family?.id]],
      orderBy: {updatedAt: 'desc', 'id': 'desc'},
      perPage: 5,
    });
    setBlogs(data);
  }, []);

  return <div className={`blog-section section`}>
    <Container>
      <h2 className="second-font heading2">{trans('family:latestBlogPosts')}</h2>
      <Button href={app.pageUrl('/blog')}
              endIcon={<ArrowRightAlt/>}
              color="error"
              size="large">
        {trans('morePosts')}
      </Button>
      <div className="mt-3">
        <BlogGrid blogs={blogs}/>
      </div>
    </Container>
  </div>
};

export default BlogSection;