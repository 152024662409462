import React from 'react';
import MainBannerSection from "~@app/module/home/component/section/MainBannerSection"
import TimelineSection from "~@app/module/home/component/section/TimelineSection"
import SimplePage from "~@app/component/page/SimplePage"
import BlogSection from "~@app/module/home/component/section/BlogSection"
import OutroSection from "~@app/module/home/component/section/OutroSection"
import _ from "lodash"
import MemberCollage from "~@app/module/family/component/member/MemberCollage"
import FamilyMember from "~@app/module/core/model/FamilyMember"

const HomePage = () => {
  return <SimplePage variant="blank">
    <MainBannerSection/>
    <TimelineSection/>
    <BlogSection/>
    <OutroSection className="app-bg-dark"/>
  </SimplePage>
};

export default HomePage;