import {Box, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import {RadioProps} from '@mui/material/Radio/Radio'
import React, {FC, useMemo, useState} from 'react';
import '~@app/module/home/component/section/MainBannerSection.scss'
import useApp from '~@app/component/app/UseApp'
import LayoutBackground from '~@app/component/layout/LayoutBackground'
import {SearchBox} from '~@app/module/common/component/SearchBox'
import useFamily from '~@app/module/family/context/FamilyContext'
import FamilyUtil from '~@app/module/family/util/FamilyUtil'
import {trans} from '~@core/util/StringUtil'

type CustomRadioProps = RadioProps
const CustomRadio: FC<CustomRadioProps> = ({...props}) => <Radio
  {...props}
  sx={{
    color: '#FFF',
    '&.Mui-checked': {
      color: '#FFF',
    }
  }}/>

const MainBannerSection = () => {
  const {family} = useFamily()
  const [searchMode, setSearchMode] = useState('site')
  const app = useApp() 

  const content = useMemo(() => ({
    title: FamilyUtil.getPropertyValue(family, 'homeTitle', trans('family:everyFamilyHasAStory')),
    subTitle: FamilyUtil.getPropertyValue(family, 'homeSubTitle', <>This Story Begins In 1878,<br/>The Year <a
      href="https://preview.lsvr.sk/lineago/family/john-lewis/">John Lewis</a> Was Born </>),
  }), [family])

  const onSearch = (event, text) => {
    if (!text) {
      return;
    }
    if (searchMode === 'member') {
      const url = app.pageUrl('family', {q: text})
      app.navigate(url);
    } else {
      const url = app.pageUrl('search', {q: text})
      app.navigate(url);
    }
  }

  const renderBannerContent = () => <div className="banner-content-wrapper">
    <div className="banner-content">
      <div className="content-heading">
        <h1 className="content-subtitle">{content.subTitle}</h1>
        <p className="content-title second-font">
          {content.title}
        </p>
      </div>
      <Box className="search-box-wrapper" sx={{
        width: '50%',
      }}>
        <SearchBox onSearch={onSearch}/>
        <RadioGroup row className="ms-5 mt-2" value={searchMode}
          onChange={(event, value) => {
            setSearchMode(value)
          }}>
          <FormControlLabel value="site" disableTypography control={<CustomRadio/>} label={trans('searchEverything')}/>
          <FormControlLabel value="member" disableTypography control={<CustomRadio/>} label={trans('searchFamilyMembers')}/>
        </RadioGroup>
      </Box>
    </div>
  </div>

  return <div className="main-banner-section section">
    <LayoutBackground image={FamilyUtil.getPropOfJsonPropertyValue(family, '$media$homeBackgroundImage', 'links.xlarge')}/>
    {renderBannerContent()}
  </div>
}
;

export default MainBannerSection;